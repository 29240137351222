import axios from 'axios'
import { handleRetry, logError } from './errors'
import { useOrderStore } from '../hooks/useOrderStore'
import 'fast-text-encoding'
import { CATEGORY_TRAVEL } from '../constants'

interface Event {
    type: string
    data?: string
    path?: string
    host?: string
    key?: string
}

declare const window: Window & { dataLayer: Array<Record<string, unknown>> }

const config = (token: string) => {
    return {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
}

const getAuthToken = (): null | string => {
    const userDetails = useOrderStore.getState().userDetails
    if (userDetails?.bearer !== undefined) {
        return userDetails?.bearer
    }

    return null
}

const isStaging = (): boolean => {
    if (typeof window !== 'undefined') {
        return window.location.host.includes('boisterous-flan') || process.env.NODE_ENV === 'development'
    }

    return false
}

const sendEvent = async (event: Event, data?: object, attempt = 1): Promise<void> => {
    if (!isStaging() && typeof window !== 'undefined') {
        const authToken = getAuthToken()
        const eventKey = getEventKey()
        const eventWithParams = {
            path: window.location.pathname,
            host: window.location.host,
            data: data !== null ? JSON.stringify(data) : '',
            ...event
        }
        if (eventKey !== '') {
            eventWithParams.key = eventKey
        }
        const encodedEvent = encodeEvent(eventWithParams)
        await axios
            .post(`${process.env.REACT_APP_API_URL}/api/v1/event`, { event: encodedEvent }, authToken !== null ? config(authToken) : {})
            .then((response) => {
                if (eventKey === '' && Boolean(response.data.data?.key)) {
                    setEventKey(response.data.data?.key)
                }
            })
            .catch((error) => {
                handleRetry(error, attempt, () => {
                    void sendEvent(event, data, attempt + 1)
                })
                    .then(() => {})
                    .catch((error) => {
                        logError(error, { message: 'Error occurred calling sendEvent()', event: JSON.stringify(eventWithParams) }, false)
                    })
            })
    } else {
        console.log(event, data)
    }
}

const bytesToBase64 = (bytes: any): string => {
    let binString = ''
    for (let i = 0; i < bytes.length; i++) {
        binString += String.fromCharCode(bytes[i])
    }
    return btoa(binString)
}

const encodeEvent = (event: Event): string => {
    // There was an encoding issue being thrown sometimes, this complex line of code should fix it
    // Ref https://developer.mozilla.org/en-US/docs/Glossary/Base64#the_unicode_problem
    return bytesToBase64(new TextEncoder().encode(JSON.stringify(event)))
}

const getEventKey = (): string => {
    try {
        const key = localStorage.getItem('key')
        return key ?? ''
    } catch (e) {
        return ''
    }
}

const setEventKey = (key: string): void => {
    localStorage.setItem('key', key)
}

export const sendPageView = async (data?: object) => {
    await sendEvent({ type: 'pageView' }, data)
}

export const sendClick = async (data?: object) => {
    await sendEvent({ type: 'click' }, data)
}

export const sendConversion = async (data?: object) => {
    await sendEvent({ type: 'conversion' }, data)
}

export const sendCompatibilitySearch = async (data?: object) => {
    await sendEvent({ type: 'compatibilitySearch' }, data)
}

export const sendCountrySearch = async (data?: object) => {
    await sendEvent({ type: 'countrySearch' }, data)
}

export const sendErrorEvent = async (data?: object) => {
    await sendEvent({ type: 'error' }, data)
}

export const sendEcommerceEvent = (event: string, data: object = {}): void => {
    if (!isStaging() && window?.dataLayer !== undefined) {
        window.dataLayer.push({ ecommerce: null }) // Clear the previous ecommerce object.
        window.dataLayer.push({ event, ...data })
    } else {
        console.log(event, data)
    }
}

const buildItemFromPlan = (plan: MobilePlan): object => {
    return {
        item_id: plan.id,
        item_name: plan.category === CATEGORY_TRAVEL ? `${plan.country}-${plan.name}-${plan.days}-days` : plan.name,
        discount: plan.promo !== undefined && plan.promo !== null ? plan.promo.amount : 0,
        item_category: plan.category,
        price: plan.price,
        quantity: 1
    }
}

export const sendEcommerceViewItemList = (plans: MobilePlan[]): void => {
    const itemList = plans.map((plan) => buildItemFromPlan(plan))
    sendEcommerceEvent('view_item_list', {
        ecommerce: {
            item_list_id: window.location.pathname.replaceAll('/', ''),
            items: itemList
        }
    })
}

export const sendEcommerceViewItem = (plan: MobilePlan): void => {
    sendEcommerceEvent('view_item', {
        ecommerce: {
            currency: 'AUD',
            value: plan.price,
            items: [buildItemFromPlan(plan)]
        }
    })
}

export const sendEcommerceAddToCartEvent = (plan: MobilePlan): void => {
    sendEcommerceEvent('add_to_cart', {
        ecommerce: {
            currency: 'AUD',
            value: plan.price,
            items: [buildItemFromPlan(plan)]
        }
    })
}

// Triggered when first OF page is loaded and initialised in the API
export const sendEcommerceViewCartEvent = (plan: MobilePlan): void => {
    sendEcommerceEvent('view_cart', {
        ecommerce: {
            currency: 'AUD',
            value: plan.price,
            items: [buildItemFromPlan(plan)]
        }
    })
}

// Triggered when user submits first form (or logs in) on OF
export const sendEcommerceBeginCheckoutEvent = (plan: MobilePlan, referralCode = '', userDetails: OrderFormUserInput | null = null): void => {
    sendEcommerceEvent('begin_checkout', {
        ecommerce: {
            currency: 'AUD',
            value: plan.price,
            coupon: referralCode,
            items: [buildItemFromPlan(plan)],
            email: userDetails !== null ? (userDetails.email ?? '') : '',
            phone: userDetails !== null ? (userDetails.mobile ?? '') : ''
        }
    })
}

// Triggered when user submits service details form on OF
export const sendEcommerceAddShippingEvent = (plan: MobilePlan, referralCode = '', userDetails: OrderFormUserInput | null = null): void => {
    sendEcommerceEvent('add_shipping_info', {
        ecommerce: {
            currency: 'AUD',
            value: plan.price,
            coupon: referralCode,
            items: [buildItemFromPlan(plan)],
            email: userDetails !== null ? (userDetails.email ?? '') : '',
            phone: userDetails !== null ? (userDetails.mobile ?? '') : ''
        }
    })
}

// Triggered when user submits payment details form on OF
export const sendEcommerceAddPaymentEvent = (plan: MobilePlan, referralCode = '', userDetails: OrderFormUserInput | null = null): void => {
    sendEcommerceEvent('add_payment_info', {
        ecommerce: {
            currency: 'AUD',
            value: plan.price,
            coupon: referralCode,
            items: [buildItemFromPlan(plan)],
            email: userDetails !== null ? (userDetails.email ?? '') : '',
            phone: userDetails !== null ? (userDetails.mobile ?? '') : ''
        }
    })
}

// Triggered when user views the /complete page after purchase
export const sendEcommercePurchaseEvent = (plan: MobilePlan, orderCode = '', referralCode = '', userDetails: OrderFormUserInput | null = null): void => {
    sendEcommerceEvent('purchase', {
        ecommerce: {
            currency: 'AUD',
            value: plan.price,
            transaction_id: orderCode,
            coupon: referralCode,
            items: [buildItemFromPlan(plan)],
            email: userDetails !== null ? (userDetails.email ?? '') : '',
            phone: userDetails !== null ? (userDetails.mobile ?? '') : ''
        }
    })
}

export const sendTikTokAddToCart = (plan: MobilePlan): void => {
    if (!isStaging() && typeof window !== 'undefined' && (window as any).ttq !== undefined) {
        ;(window as any).ttq.track('AddToCart', {
            contents: [
                {
                    content_id: plan.id,
                    content_name: plan.category === CATEGORY_TRAVEL ? `${plan.country}-${plan.name}-${plan.days}-days` : plan.name
                }
            ],
            value: plan.price,
            currency: 'AUD'
        })
    }
}

export const sendTikTokCompleteOrder = (plan: MobilePlan): void => {
    if (!isStaging() && typeof window !== 'undefined' && (window as any).ttq !== undefined) {
        ;(window as any).ttq.track('PlaceAnOrder', {
            contents: [
                {
                    content_id: plan.id,
                    content_name: plan.category === CATEGORY_TRAVEL ? `${plan.country}-${plan.name}-${plan.days}-days` : plan.name
                }
            ],
            value: plan.price,
            currency: 'AUD'
        })
    }
}

export const sendKlaviyoIdentifyEvent = (data: { email: string; $first_name?: string; $last_name?: string; mobile?: string }, onComplete?: () => void): void => {
    if (!isStaging() && typeof window !== 'undefined' && (window as any).klaviyo !== undefined) {
        ;(window as any).klaviyo.identify(data).then(onComplete)
    }
}

export const sendKlaviyoEvent = (event: string, data?: object): void => {
    if (!isStaging() && typeof window !== 'undefined' && (window as any).klaviyo !== undefined) {
        ;(window as any).klaviyo.push(['track', event, data])
    }
}

export const sendKlaviyoStartCheckoutEvent = (orderCode: string, selectedPlan: MobilePlan) => {
    sendKlaviyoEvent('Started Checkout', {
        $event_id: `${orderCode}-${Date.now()}`,
        $value: selectedPlan.price,
        Items: [
            {
                ProductName: selectedPlan.category === CATEGORY_TRAVEL ? `${selectedPlan.country}-${selectedPlan.name}-${selectedPlan.days}-days` : selectedPlan.name,
                Price: selectedPlan.price,
                ProductID: selectedPlan.id
            }
        ]
    })
}
